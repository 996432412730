.plans_title {
    width: 100%;
    text-align: center;
    margin: 30px auto;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 33px;
    font-family: Trajan;
    font-weight: bold;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.globalPlan_container {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
    padding: 0 0 20px 0;
    border-bottom: 2px solid rgba(186, 99, 249, 1);

    @media screen and (max-width: 900px) {
        width: 90%;
    }
}

.plan_element {
    width: 60%;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding: 20px 0 40px 0;

    @media screen and (max-width: 900px) {
        width: 90%;
    }

    &_title {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 30px;
        font-family: Trajan;
        color: rgba(186, 99, 249, 1);
    }

    &_desc {
        margin: 50px auto;

        p {
            padding: 5px 0;
        }

        a {
            color: rgba(186, 99, 249, 1);
        }

        ul {
            padding-left: 50px;
        }

        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
    }

    &_image {
        width: 50%;
        padding: 0 0 50px 0;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        @media screen and (max-width: 900px) {
            width: 90%;
        }

        img {
            width: 100%;
            max-width: 1900px;
        }

        border-bottom: 2px solid gray;
    }
}
