//FONT ABHAYA LIBRE
$Abhaya: url('https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;600;800&display=swap');
@font-face {
    font-family: Abhaya;
    src: $Abhaya;
}

//FONT TRAJAN PRO
@font-face {
    font-family: Trajan;
    src: url('../assets/fonts/Trajan/Trajan-Pro.ttf');
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: rgb(24, 24, 24);
    font-family: 'Lato', Arial, Helvetica, sans-serif;
    color: gray;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.error_empty {
    color: white;
    font-size: 20px;
    margin: 50px auto;
    text-align: center;
}

.loading {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.error {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
