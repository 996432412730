.grooming_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_title {
        background: -webkit-linear-gradient(
            302deg,
            rgba(219, 193, 238, 1) 0%,
            rgba(186, 99, 249, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 33px;
        font-family: Trajan;
        font-weight: bold;
        text-shadow: 0.1em 0.1em 0.2em black;
        margin: 20px auto 50px auto;
    }
}

.grooming_wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
        width: 95%;
        flex-direction: column;
    }
}

.grooming_left {
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
        width: 100%;
        height: 100%;
    }

    &_desc {
        width: 100%;
        margin: 20px auto;
        padding: 0 20px;

        text-align: justify;

        p {
            padding: 5px 0;
        }

        a {
            color: rgba(186, 99, 249, 1);
        }

        ul {
            padding-left: 50px;
        }

        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
    }

    &_logo {
        width: 100%;
        margin: 20px auto;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            margin: 20px auto;
            display: flex;
            justify-content: center;
            align-items: center;

            @media screen and (max-width: 1200px) {
                width: 100%;
                padding: 20px 5px;
            }

            img {
                height: 130px;
                width: auto;
                max-width: 100%;
                border-radius: 3px;
            }
        }
    }
}

.grooming_right {
    width: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
        width: 100%;
        height: 100%;
    }

    &_image {
        margin: 75px auto;
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 1200px) {
            margin: 20px auto;
            display: none;
        }

        img {
            width: 70%;
            max-width: 650px;
            border-radius: 10px;
        }
    }

    &_pricing {
        margin: 75px auto;

        @media screen and (max-width: 1200px) {
            margin: 20px auto;
        }

        .hotel_price {
            &_title {
                width: 100%;
                margin: 10px auto 50px auto;
                color: rgba(186, 99, 249, 1);
                font-family: Trajan;
                font-size: 30px;
                font-weight: bold;
                text-align: center;
            }
            &_table {
                border-radius: 5px;
                background: rgba(204, 120, 186, 0.25);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5.6px);
                -webkit-backdrop-filter: blur(5.6px);
                border: 1px solid rgba(204, 120, 186, 0.23);
                /* From https://css.glass */
                background: rgba(204, 120, 186, 0.25);
                border-radius: 16px;
                box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
                backdrop-filter: blur(5.6px);
                -webkit-backdrop-filter: blur(5.6px);
                border: 1px solid rgba(204, 120, 186, 0.23);

                thead {
                    padding: 15px;
                    color: white;
                    font-weight: bold;

                    td {
                        padding: 10px 50px;
                        text-align: center;
                    }
                }

                tbody {
                    td {
                        text-align: center;
                        color: white;
                        padding: 15px;
                    }
                }
            }
        }
    }
}

.grooming_gallery {
    width: 80%;
    margin: 50px auto 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &_title {
        font-size: 30px;
        color: rgba(186, 99, 249, 1);
        font-weight: bold;
        font-family: Trajan;
        margin: 30px auto;
        width: 100%;
        text-align: center;
    }

    &_images {
        img {
            height: 175px;
            width: auto;
            margin: 20px 15px;
            border-radius: 5px;
        }
    }
}
