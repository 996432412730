.hotel_container {
    width: 95%;
    margin: 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hotel_title {
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 33px;
    font-family: Trajan;
    font-weight: bold;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.hotel_desc {
    color: gray;
    font-size: 18px;
    padding: 10px 25px;
    width: 70%;
    margin: 50px auto;
    text-align: justify;

    p {
        padding: 5px 0;
    }

    a {
        color: rgba(186, 99, 249, 1);
    }

    ul {
        padding-left: 50px;
    }

    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }

    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 10px;
    }
}

.hotel_price {
    &_title {
        width: 100%;
        margin: 10px auto 50px auto;
        color: rgba(186, 99, 249, 1);
        font-family: Trajan;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }
    &_table {
        border-radius: 5px;
        background: rgba(204, 120, 186, 0.25);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.6px);
        -webkit-backdrop-filter: blur(5.6px);
        border: 1px solid rgba(204, 120, 186, 0.23);
        /* From https://css.glass */
        background: rgba(204, 120, 186, 0.25);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5.6px);
        -webkit-backdrop-filter: blur(5.6px);
        border: 1px solid rgba(204, 120, 186, 0.23);

        thead {
            padding: 15px;
            color: white;
            font-weight: bold;

            td {
                padding: 10px 50px;
                text-align: center;
            }
        }

        tbody {
            td {
                text-align: center;
                color: white;
                padding: 15px;
            }
        }
    }
}

.hotel_gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_title {
        width: 100%;
        margin: 10px auto 50px auto;
        color: rgba(186, 99, 249, 1);
        font-family: Trajan;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
    }
    &_images {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        img {
            height: 200px;
            width: auto;
            margin: 20px 30px;
        }
    }
}
