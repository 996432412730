// ====================
// Litters Category
// ====================

.litters_category_container {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
        width: 95%;
        flex-direction: column;
    }
}

.litters_category_element {
    width: 40%;
    height: auto;
    margin: 20px 10px;
    text-align: center;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.litters_category_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
        .litters_category_image {
            transform: scale(1.1);
        }
        .litters_category_name {
            background: -webkit-linear-gradient(
                180deg,
                rgba(219, 193, 238, 1) 0%,
                rgba(186, 99, 249, 1) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.litters_category_image {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s linear;

    img {
        height: 400px !important;
        width: auto;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
            width: 100% !important;
            height: auto !important;
        }
    }
}

.reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.litters_category_name {
    font-size: 35px;
    margin: 10px auto;
    font-family: Abhaya;
    font-weight: bold;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s linear;
}

//=============================
//   Litters List
//=============================

.litters_list_container {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
        width: 95%;
        min-height: 50vh;
    }

    &_name {
        width: 100%;
        margin: 0 auto 70px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-family: Trajan;
        background: -webkit-linear-gradient(
            302deg,
            rgba(219, 193, 238, 1) 0%,
            rgba(186, 99, 249, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        font-weight: bold;
        text-shadow: 0.1em 0.1em 0.2em black;
    }

    &_list {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        a {
            margin: 20px 15px;
        }
    }
}

.litters_list_element {
    width: 100%;
    max-width: 300px;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    &:hover {
        .litter_list_image {
            transform: rotate(4deg);
        }

        .litter_list_name {
            transform: scale(1.1);
        }
    }
}

.litter_list_image {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s linear;
    cursor: pointer;
    height: 200px;
    width: 100%;

    &_img {
        height: 200px !important;
        width: auto !important;
    }
}

.litter_list_name {
    text-align: center;
    font-size: 22px;
    margin: 20px auto;
    background: -webkit-linear-gradient(
        180deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: transform 0.3s linear;
    cursor: pointer;

    span {
        font-size: 16px;
    }
}

//=================
// Litter Details
//=================

.litter_details_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.litter_details_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 35px;
    margin: 20px 0;
    font-weight: bold;
}

.litter_details_birth {
    font-size: 18px;
    margin: 10px auto;
}

.litter_details_desc {
    width: 50%;
    padding: 10px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 18px;

    p {
        padding: 5px 0;
    }

    a {
        color: rgba(186, 99, 249, 1);
    }

    ul {
        padding-left: 50px;
    }

    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }

    @media screen and (max-width: 1200px) {
        width: 95%;
        padding: 10px 15px;
    }
}

.litter_gallery {
    width: 75%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
        width: 95%;
    }

    &_title {
        margin: 10px auto 30px auto;
        color: rgba(186, 99, 249, 1);
        font-family: Trajan;
        font-size: 30px;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }

    &_images {
        width: 80%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        img {
            height: 230px;
            margin: 20px 15px;
            transition: transform 0.3s linear;
            cursor: pointer;

            &:hover {
                transform: rotate(3deg);
            }
        }
    }
}
