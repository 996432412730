.contact_container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact_title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 33px;
    font-family: Trajan;
    font-weight: bold;
    text-shadow: 0.1em 0.1em 0.2em black;
    text-align: center;
}

.contact_wrapper {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin: 75px auto 130px;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 95%;
        margin: 75px auto 20px auto;
    }
}

.contact_wrapper_data {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: gray;
    font-size: 20px;

    @media screen and (max-width: 1200px) {
        width: 95%;
    }

    span {
        margin: 5px 0;
    }

    a {
        color: rgb(205, 141, 250);
        text-shadow: 0.1em 0.1em 0.2em black;
        transition: color 0.2s linear;

        &:hover {
            color: rgb(158, 77, 215);
        }
    }
}

.contact_wrapper_social {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1200px) {
        width: 95%;
        margin: 50px auto 10px auto;
    }
}

.contact_social_logo {
    margin: 0 auto 50px auto;

    img {
        width: 550px !important;
        max-width: 100% !important;
    }
}

.contact_social_icons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    img {
        width: 50px !important;
    }
}

.icons {
    margin: 10px 20px;
    transition: transform 0.3s linear;

    &:hover {
        transform: scale(1.1);
    }
}

.partners_container {
    width: 80%;
    margin: 50px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 900px) {
        width: 100%;
        padding: 10px;
        margin: 10px auto;
    }
}

.partners_element {
    margin: 10px 50px;
    transition: transform 0.3s linear;

    &:hover {
        transform: scale(1.05);
    }

    img {
        height: 100px !important;
        width: auto !important;
    }
}
