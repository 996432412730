.container {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    img {
        width: 100%;
    }
}

.mobile_nav {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: #2f1641;
    z-index: 100001;
    left: -100%;
    transition: left 0.3s linear;

    nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        ul {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            list-style-type: none;
            padding: 0;

            li {
                font-size: 20px;
                letter-spacing: 2px;
                margin: 20px auto;
                color: white;
                transition: transform 0.2s linear;

                &:last-child {
                    margin-top: 70px;

                    &:hover {
                        color: rgb(202, 202, 202);
                        transform: scale(1);
                    }
                }

                &:hover {
                    color: rgb(202, 202, 202);
                    transform: scale(1.1);
                }
            }
        }
    }

    &_active {
        left: 0;
    }

    &_close {
        position: absolute;
        top: 20px;
        left: 20px;
        width: 32px;
        height: 32px;
        filter: invert(1);
        cursor: pointer;
        transition: transform 0.3s linear;

        &:hover {
            transform: scale(1.1);
        }

        img {
            width: 100%;
        }
    }
}

.mobile_hamburger {
    display: none;
    position: absolute;
    top: 20px;
    left: 20px;
    width: 64px;
    height: 64px;
    transition: transform 0.3s linear;
    filter: invert(1);
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }

    @media screen and (max-width: 1200px) {
        display: block;
    }
}

.nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 10px;
    z-index: 100;

    @media screen and (max-width: 1200px) {
        display: none;
    }

    &_lang {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        &_flag {
            margin: 0 15px;
            cursor: pointer;
            transition: transform 0.3s linear;

            &:hover {
                transform: scale(1.1);
            }

            img {
                height: 40px !important;
                width: auto !important;

                transition: transform 0.2s linear;
            }
        }
    }

    ul {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 0;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            list-style-type: none;
            margin: 0 15px;

            a {
                color: #2f1641;
                font-size: 18px;
                text-shadow: 2px 2px 6px #2f1641;
                font-family: 'Lato', Arial, sans-serif;
                letter-spacing: 1px;
                transition: color, transform 0.2s linear;

                &:hover {
                    color: #5d337c;
                    transform: scale(1.1);
                }
            }
        }
    }
}
