// ====================
// Dogs Category
// ====================

.dogs_category_container {
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
        width: 95%;
        flex-direction: column;
    }
}

.dogs_category_element {
    width: 40%;
    height: auto;
    margin: 20px 10px;
    text-align: center;

    @media screen and (max-width: 1200px) {
        width: 100%;
    }
}

.dogs_category_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
        .dogs_category_image {
            transform: scale(1.1);
        }
        .dogs_category_name {
            background: -webkit-linear-gradient(
                180deg,
                rgba(219, 193, 238, 1) 0%,
                rgba(186, 99, 249, 1) 100%
            );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

.dogs_category_image {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s linear;

    img {
        height: 400px !important;
        width: auto;
        cursor: pointer;

        @media screen and (max-width: 1200px) {
            width: 100% !important;
            height: auto !important;
        }
    }
}

.reverse {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.dogs_category_name {
    font-size: 35px;
    margin: 10px auto;
    font-family: Abhaya;
    font-weight: bold;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transition: all 0.3s linear;
}

//====================
//    Dogs List
//====================

.dogs_list_container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_title {
        background: -webkit-linear-gradient(
            302deg,
            rgba(219, 193, 238, 1) 0%,
            rgba(186, 99, 249, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        margin: 20px 0;
        font-weight: bold;
        text-shadow: 0.1em 0.1em 0.2em black;
        font-family: Trajan;
        text-align: center;
    }

    @media screen and (max-width: 1200px) {
        width: 95%;
    }
}

.dogs_list_memory {
    width: 80%;
    margin: 100px auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_title {
        background: -webkit-linear-gradient(
            302deg,
            rgba(219, 193, 238, 1) 0%,
            rgba(186, 99, 249, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        margin: 20px 0;
        font-weight: bold;
        text-shadow: 0.1em 0.1em 0.2em black;
        font-family: Trajan;
        text-align: center;
    }
}

.dogs_list_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    &:nth-child(odd) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row-reverse;

        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        margin: 50px auto;
    }

    img {
        height: 300px;
        width: auto;
        max-width: 100%;
        margin: 0 50px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            height: auto;
            margin: 15px auto;
        }
    }

    .dogName {
        width: 60%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px;
        font-family: Abhaya;
        font-weight: bold;

        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        transition: transform 0.2s linear;
        text-align: center;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        &:hover {
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            transform: scale(1.1);

            @media screen and (max-width: 1200px) {
                transform: scale(1.03);
            }
        }
    }
}

.male {
    background: -webkit-linear-gradient(
        0deg,
        rgba(215, 119, 246, 1) 0%,
        rgba(85, 1, 148, 1) 100%
    );

    &:hover {
        background: -webkit-linear-gradient(
            180deg,
            rgba(215, 119, 246, 1) 0%,
            rgba(85, 1, 148, 1) 100%
        );
    }
}

.female {
    background: -webkit-linear-gradient(
        0deg,
        rgba(255, 112, 204, 1) 0%,
        rgba(162, 4, 79, 1) 100%
    );

    &:hover {
        background: -webkit-linear-gradient(
            180deg,
            rgba(255, 112, 204, 1) 0%,
            rgba(162, 4, 79, 1) 100%
        );
    }
}

//========================
// Dog Details
//========================

.dog_container {
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dog_wrapper_name {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.dog_name {
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;
    margin: 20px 0;
    font-weight: bold;
    text-shadow: 0.1em 0.1em 0.2em black;
    font-family: Trajan;
}

.dog_wrapper_desc {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        width: 100%;
    }
}

.dog_img {
    width: 50%;
    height: auto;

    @media screen and (max-width: 1200px) {
        width: 100%;
        padding: 5px;
    }

    img {
        width: 750px;
        max-width: 100%;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }
    }
}

.dog_desc {
    width: 50%;
    color: gray;
    font-size: 18px;
    padding: 10px;

    p {
        padding: 5px 0;
    }

    a {
        color: rgba(186, 99, 249, 1);
    }

    ul {
        padding-left: 50px;
    }

    h1 {
        font-size: 24px;
    }
    h2 {
        font-size: 22px;
    }
    h3 {
        font-size: 20px;
    }
}

.dog_pedigree {
    display: flex;
    margin: 20px 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 30px;

    div {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    img {
        max-width: 80%;
        height: auto;
        display: block;
    }
}

.dog_gallery {
    margin: 20px 0 50px 0;
    justify-content: center;
    align-items: center;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    &_title {
        background: -webkit-linear-gradient(
            302deg,
            rgba(219, 193, 238, 1) 0%,
            rgba(186, 99, 249, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 30px;
        margin: 50px;
    }

    &_images {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-wrap: wrap;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        img {
            height: 175px;
            width: auto;
            margin: 15px 20px;
            transition: transform 0.3s linear;

            &:hover {
                transform: scale(1.05) rotate(3deg);
            }
        }
    }
}
