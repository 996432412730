.title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 100px;
    background: -webkit-linear-gradient(
        302deg,
        rgba(219, 193, 238, 1) 0%,
        rgba(186, 99, 249, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 33px;
    font-family: Trajan;
    font-weight: bold;
    text-shadow: 0.1em 0.1em 0.2em black;
}

.news_element {
    width: 65%;
    margin: 30px auto 70px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &_title {
        width: 100%;
        text-align: center;
        font-size: 23px;
        margin: 20px auto;
        color: gray;
        font-family: Trajan;
    }

    &_date {
        font-size: 14px;
    }

    @media screen and (max-width: 900px) {
        width: 95%;
    }

    @media screen and (max-width: 1200px) {
        width: 80%;
    }
}

.news_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;

    @media screen and (max-width: 1200px) {
        flex-direction: column;
    }

    &_desc {
        width: 50%;
        font-size: 16px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            padding: 10px;
            text-align: center;
        }

        p {
            padding: 5px 0;
        }

        a {
            color: rgba(186, 99, 249, 1);
        }

        ul {
            padding-left: 50px;
        }

        h1 {
            font-size: 24px;
        }
        h2 {
            font-size: 22px;
        }
        h3 {
            font-size: 20px;
        }
    }

    &_images {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;

        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
        }

        img {
            height: 150px;
            margin: 15px 20px;
            transition: transform 0.3s linear;
            cursor: pointer;

            &:hover {
                transform: scale(1.1);
            }
        }
    }
}

.news_big_foto {
    height: 250px !important;
}

.news_medium_foto {
    height: 175px !important;
}
